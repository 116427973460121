import { useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import "./modal.css";
import juan from "../../Assets/juan.png";

const Modal = ({ setIsOpen }) => {
  return (
    <>
      <div className="darkBG" onClick={() => setIsOpen(false)} />
      <div className="centered">
        <div className="modal">
          <div className="modalHeader">
            {/* <h5 className="heading">Dialog</h5> */}
            <img src={juan} alt="" />
            <div>
              <h5 className="tracking-in-contract-bck">¡Bienvenido a tu empresa yucateca!</h5>
              <p>En 'Juanito Rivas', destacamos desde hace 10 años por nuestra dedicación en la venta de terrenos.</p>
            </div>
          </div>
          {/* <button className="closeBtn" onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button> */}
          <div className="modalContent">
            <p>Deseas recibir ofertas exclusivas?</p>
            <div className="offers">
              <input type="checkbox" id="exclusiveOffers" name="exclusiveOffers" />
              <label htmlFor="exclusiveOffers">Sí, deseo recibir ofertas exclusivas</label>
            </div>
          </div>
          <div className="modalActions">
            <div className="actionsContainer">
              <button className="cancelBtn" onClick={() => setIsOpen(false)}>
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
