import React, { useState } from "react";
import "./home.scss";
import Modal from "../Widgets/Modal";
import video from "../../Assets/video-home.mp4";
import { GrLocation } from "react-icons/gr";
import { BsSend  } from "react-icons/bs";
import { FiFacebook, FiInstagram } from "react-icons/fi";
import { BsListTask } from "react-icons/bs";
import { TbApps } from "react-icons/tb";
import { FaRegLightbulb, FaRegUser } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";

const Home = () => {
 //States for toggleButtons
  const [selectedOption, setSelectedOption] = useState(1);
  const handleButtonClick = (option) => {
    setSelectedOption(option);
  };

  //States for dropdown
  const [usoSuelo, setUsoSuelo] = useState('');
  const handleUsoSueloChange = (event) => {
    setUsoSuelo(event.target.value);
  }

  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className="home">
      <div className="overlay"></div>
      <video src={video} muted autoPlay loop type="video/mp4"></video>

      <div className="homeContent container">
        <div className="textDiv">
          <span className="smallText">Somos tu mejor opción</span>
          <h1 className="homeTitle">¿Buscas terrenos en yucatán?</h1>
        </div>

        <div className="cardDiv">
          <div className="toggleButtonGroup">
            <button
              className={`toggle-button ${
                selectedOption === 1 ? "active" : ""
              }`}
              onClick={() => handleButtonClick(1)}
            >
              Inversión a corto plazo
            </button>
            <button
              className={`toggle-button ${
                selectedOption === 2 ? "active" : ""
              }`}
              onClick={() => handleButtonClick(2)}
            >
              Inversión a mediano plazo
            </button>
            <button
              className={`toggle-button ${
                selectedOption === 3 ? "active" : ""
              }`}
              onClick={() => handleButtonClick(3)}
            >
              Inversión a largo plazo
            </button>
            <button
              className={`toggle-button ${
                selectedOption === 4 ? "active" : ""
              }`}
              onClick={() => handleButtonClick(4)}
            >
              Herencia patrimonial 
            </button>
          </div>

          <div className="destinationInput">
            <label htmlFor="city">Indique una zona:</label>
            <div className="input flex">
              <input type="text" placeholder="Escriba aquí la zona..." />
              <GrLocation className="icon" />
            </div>
          </div>

          <div className="usoSueloInput">
            <label htmlFor="suelo">Uso de suelo</label>
            <div className="input flex">
              <select
                id="suelo"
                value={usoSuelo}
                onChange={handleUsoSueloChange}
              >
                <option value="">Selecciona el uso de suelo</option>
                <option value="Residencial">Residencial</option>
                <option value="Comercial">Comercial</option>
                <option value="Industrial">Industrial</option>
                <option value="Recreativo">Recreativo</option>
                <option value="Mixto">Mixto</option>
              </select>
              <FaRegLightbulb className="icon" />
            </div>
          </div>

          <div className="priceInput">
            <div className="label_total flex">
              <label htmlFor="price">Precio Max.</label>
              <h3 className="total">$1000000</h3>
            </div>
            <div className="input flex">
              <input type="range" max="1000000" min="50000" />
            </div>
          </div>

          <div className="textInput">
            <label htmlFor="caract">Características ideales</label>
            <div className="input flex">
              <input
                type="text"
                placeholder="Describe las amenidades que deseas"
              />
              <FaRegLightbulb className="icon" />
            </div>
          </div>
          
          <div className="nameInput">
            <label htmlFor="name">Nombre</label>
            <div className="input flex">
              <input type="text" placeholder="Datos de contacto" />
              <FaRegUser className="icon" />
            </div>
          </div>

          <div className="emailInput">
            <label htmlFor="email">Email</label>
            <div className="input flex">
              <input type="text" placeholder="Datos de contacto" />
              <MdOutlineMailOutline className="icon" />
            </div>
          </div>

          <div className="dateInput">
              <label htmlFor="date">¿Desea agendar una cita?</label>
              <div className="input flex">
                  <input type="datetime-local"/>
              </div>
          </div>

          <div className="searchOptions flex" onClick={() => setIsOpen(true)}>
            <BsSend className="icon"></BsSend>
            <span>Enviar</span>
          </div>
          {isOpen && <Modal setIsOpen={setIsOpen} />}
        </div>

        <div className="homeFooterIcons flex">
          <div className="rightIcons">
            <FiFacebook className="icon"></FiFacebook>
            <FiInstagram className="icon"></FiInstagram>
          </div>
          <div className="leftIcons">
            <BsListTask className="icon" />
            <TbApps className="icon" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
