import React from "react";
// import './main.css';
import './main.scss';
import { FaMapMarkerAlt, FaClipboardCheck } from "react-icons/fa";

import img1 from '../../Assets/img(1).jpg'
import img2 from '../../Assets/img(2).jpg'
import img3 from '../../Assets/img(3).jpg'
import alan from '../../Assets/alan.png'
import mario from '../../Assets/mario.png'
import juan from '../../Assets/juan.png'

import anuncio from '../../Assets/bg-footer.jpg'

import Carousel from "../Widgets/Caroussel";

const Main = () => {
    const Data = [
        {
            id: 1,
            imgSrc: img1,
            terrenoTitle: "Privada Conkal",
            location: "Conkal",
            price: "$800",
            description: 'La mejor opcion en inmuebles los encuentras en conkal, zona cercan a merida donde puedes tener una vida tranquila.'
        },
        {
            id: 2,
            imgSrc: img2,
            terrenoTitle: "Privada Conkal",
            location: "Conkal",
            price: "$800",
            description: 'La mejor opcion en inmuebles los encuentras en conkal, zona cercan a merida donde puedes tener una vida tranquila.'
        },
        {
            id: 3,
            imgSrc: img3,
            terrenoTitle: "Privada Conkal",
            location: "Conkal",
            price: "$800",
            description: 'La mejor opcion en inmuebles los encuentras en conkal, zona cercan a merida donde puedes tener una vida tranquila.'
        },
    ];
    const items = [
        {
          image: alan,
          subtitle: 'Alan Alcolea',
          title: 'Desarrollador de software'
        },
        {
          image: mario,
          subtitle: 'Mario Arjona',
          title: 'Funda'
        },
        {
            image: juan,
            subtitle: 'Juan Rivas',
            title: 'Jefe'
        },
      ];


    return ( 
        <section className="main container section">
            <div className="secTitle">
                <h3 className="title">
                    Nuestro equipo
                </h3>
            </div>
            <Carousel items={items} />
            <div className="secTitle">
                <h3 className="title">
                    Anúnciate con nosotros
                </h3>
            </div>
            <div className="banner">
                <img src={anuncio} alt="" />
            </div>
            <div className="secTitle">
                <h3 className="title">
                    Inmuebles destacados
                </h3>
            </div>
            <div className="secContent grid">
                {
                    Data.map(({id, imgSrc, terrenoTitle, location, price, description})=>{
                        return(
                            <div key={id}
                            className="singleDestination">
                                <div className="imageDiv">
                                    <img src={imgSrc} alt={terrenoTitle} />
                                </div>
                                <div className="cardInfo">
                                    <h4 className="destTitle">{terrenoTitle}</h4>
                                    <span className="continent flex">
                                        <FaMapMarkerAlt className="icon"></FaMapMarkerAlt>
                                        <span className="name">{location}</span>
                                    </span>
                                    
                                    <div className="fees flex">
                                        <div className="price">
                                            <h5>{price}</h5>
                                        </div>
                                    </div>

                                    <div className="desc">
                                        <p>{description}</p>
                                    </div>

                                    <button className="btn flex">
                                        Details <FaClipboardCheck className="icon" /> 
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </section>
     );
}
 
export default Main;