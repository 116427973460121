import React from "react";
import './footer.scss';
import './footer.css';
import { FiSend } from "react-icons/fi";
import { FiFacebook, FiInstagram } from "react-icons/fi";
import video from '../../Assets/video-footer.mp4';

const Footer = () => {
    return ( 
        <section className="footer">
            {/* <div className="bgDiv">
                <img src={img} alt="" />
            </div> */}
            <div className="videoDiv">
                <video src={video} alt="" muted autoPlay loop type="video/mp4" />
            </div>

            <div className="secContent container">
                <div className="contactDiv flex">
                    <div className="text">
                        <small>Juanito Rivas</small>
                        <h2>Expertos en terrenos</h2>
                    </div>
                    
                    <div className="inputDiv flex">
                        <input type="text" placeholder="Déjanos tu email"></input>
                        <button className="btn flex" type="submit">
                            SEND <FiSend className="icon" />
                        </button>
                    </div>
                </div>

                <div className="footerCard flex">
                    <div className="footerIntro flex">
                        <div className="logoDiv">
                            <a href="#" className="logo flex">
                                JR
                            </a>
                        </div>

                        <div className="footerParagraph">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem mollitia nisi, molestias ratione eos nihil distinctio ipsam, omnis qui voluptatibus debitis repudiandae ea placeat obcaecati ut aperiam. Expedita, itaque eveniet?
                        </div>

                        <div className="footerSocials flex">
                            <FiFacebook className="icon"/>
                            <FiInstagram className="icon"/>
                        </div>
                    </div>

                    <div className="footerLinks grid">
                        <div className="linkgroup">
                            <span className="groupTitle">Nosotros</span>
                            <li className="footerList flex"> servicios</li>
                        </div>
                    </div>

                    <div className="footerDiv flex">
                        <small>EXPERTOS EN TERRENOS</small>
                        <small>Derechos reservados JR-2024</small>
                    </div>
                </div>
            </div>
        </section>
     ); 
}
 
export default Footer;