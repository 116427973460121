import React, { useState } from 'react';
import "./navbar.scss";
import "./navbar.css";
import { MdOutlineRealEstateAgent, MdOutlineClose } from "react-icons/md";
import { TbGridDots } from "react-icons/tb";

import logo from '../../Assets/Símbolo-sinfondo-03.png';

const Navbar = () => {
  const [active, setActive] = useState('navBar');
  const showNav = () => {
    setActive('navBar activeNavbar');
  }

  const removeNavbar = () => {
    setActive('navBar');
  }
  return (
    <section className="navBarSection">
      <header className="header flex">
        <div className="logoDiv">
          <a href="#" className="logo flex">
            <h1>
              {/* <MdOutlineRealEstateAgent className="icon" /> JR. */}
              <img className='logo-JR' src={logo} alt="" />
            </h1>
          </a>
        </div>

        <div className={active}>
          <ul className="navLists flex">
            <li className="navItem">
              <a href="/" className="navLink">
                Home
              </a>
            </li>
            <li className="navItem">
              <a href="/" className="navLink">
                About
              </a>
            </li>

            <button className="btn">
                <a href="/">Book now</a>
            </button>
          </ul>
          <div onClick={removeNavbar}
          className="closeNavbar">
            <MdOutlineClose className="icon" />
          </div>
        </div>

        <div onClick={showNav}
        className="toggleNavbar">
            <TbGridDots className="icon"/>
        </div>
      </header>
    </section>
  );
};

export default Navbar;
